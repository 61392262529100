<template>
        <defaultSec class="refund-detail" :title="'< 返回'" :returnState="true">
            <el-form>
                <el-form-item style="text-align: right">
                    <el-button v-if="pageType == 3" type="danger" @click="toCheckRefund('agree')">同意退款</el-button>
                    <el-button v-if="pageType == 3" type="danger" @click="toCheckRefund('disagree')">拒绝退款</el-button>
                    <el-button v-if="pageType == 1" type="danger" @click="toApplyRefund" :disabled="applyRefundDisabled">申请退款</el-button>
                </el-form-item>
            </el-form>
            <el-row :gutter="20">
                <el-col :span="8">
                    <el-form :model="detailInfo" class="demo-form-inline" label-width="82px" size="mini">
                        <el-form-item label="售后单号">
                            <el-tag type="info">{{detailInfo.refundNo||''}}</el-tag>
                        </el-form-item>
                        <el-form-item label="退款原因">
                            <el-select v-model="detailInfo.refundReasonCode" placeholder="请选择" @change="updateReason">
                                <el-option
                                        v-for="item in refundReasonCodeValues"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-form>
                </el-col>
                <el-col :span="8">
                    <el-form :model="detailInfo" class="demo-form-inline" label-width="70px" size="mini">
                        <el-form-item label="外部单号">
                            <el-tag type="info">{{detailInfo.orderNo}}</el-tag>
                        </el-form-item>
                        <el-form-item label="退款说明">
                            <el-input v-model="detailInfo.remark"></el-input>
                        </el-form-item>
                    </el-form>
                </el-col>
                <el-col :span="8">
                    <el-form :model="detailInfo" class="demo-form-inline" label-width="85px" size="mini">
                        <el-form-item label="退款类型">
                            <el-select v-model="detailInfo.refundType" placeholder="请选择" @change="updateReason" :disabled="refundTypeDisabled">
                                <el-option
                                        v-for="item in refundTypes"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="是否退优惠券">
                            <template slot-scope="scope">
                                <el-select v-model="detailInfo.isRefundCoupon"  placeholder="是否退优惠券" :disabled="isRefundCouponDisabled">
                                    <el-option v-for="item in arr" :label="item.label" :value="item.value" :key="item.value"></el-option>
                                </el-select>
                            </template>
                        </el-form-item>
                    </el-form>
                </el-col>
            </el-row>
            <tablePagination :total="0" :pageSize="15">
                <el-table :data="detailInfo.refundProductResVoList" border size="mini">
                    <el-table-column type="index" label="序号"></el-table-column>
                    <el-table-column prop="refundNo" label="退款编号"></el-table-column>
                    <el-table-column prop="productId" label="产品id"></el-table-column>
                    <el-table-column prop="productName" label="产品名称"></el-table-column>
                    <el-table-column prop="payAmount" label="产品实付金额"></el-table-column>
                    <el-table-column prop="productNum" label="退货数量">
                        <template slot-scope="scope">
<!--                            <el-input-number size="mini" v-model="scope.row.productNum" @change="handleChangeProductNum(scope.row)" :min="0" :max="scope.row.productRefundMax" >-->
                            <el-input-number size="mini" v-model="scope.row.productNum" @change="handleChangeProductNum(scope.row)" :min="0" >
                            </el-input-number>
                        </template>
                    </el-table-column>
                    <el-table-column prop="refundCashAmountApply" label="现金退款金额">
                        <template slot-scope="scope">
                           <el-input-number v-model="scope.row.refundCashAmountApply" :min="0" :max="scope.row.productCashAmountMax" :step="0.5"  :disabled="refundCashAmountApplyDisabled">
                            </el-input-number>
                        </template>
                    </el-table-column>
                    <el-table-column prop="refundBalanceAmountApply" label="余额退款金额">
                        <template slot-scope="scope">
                            <el-input-number v-model="scope.row.refundBalanceAmountApply" :min="0" :max="scope.row.productBalanceAmountMax" :step="0.5"  :disabled="refundBalanceAmountApplyDisabled">
                            </el-input-number>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作">
                        <template slot-scope="scope">
                            <el-button type="danger" v-if="pageType == 1" plain size="mini" icon="el-icon-delete" @click="delProduct(scope.$index)" circle :disabled="delProductDisabled"></el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </tablePagination>
        </defaultSec>
</template>

<script>
    import defaultSec from '@c/defaultSection'
    import tablePagination from '@c/tablePagination'

    export default {
        data() {
            return {
                refundNo:this.$route.params.refundNo,
                orderNo:this.$route.params.orderNo,
                pageType:this.$route.params.pageType,
                orderSource:this.$route.params.orderSource,
                msgCode:'',
                detailInfo:{
                },
                refundProductResVoList:[],
                arr:[
                    {label:'否',value:2},
                    {label:'是',value:1}
                ],
                total:0,
                refundReasonCodeValues:[
                    {value:1,label:'拍错/拍多/不喜欢'},
                    {value:2,label:'商品成分描述不符'},
                    {value:3,label:'效果与商品描述不符'},
                    {value:4,label:'质量问题'},
                    {value:5,label:'生产日期/保质期描述不符'},
                    {value:6,label:'图片/产地/批号/规则等描述不符'},
                    {value:7,label:'卖家发错货'},
                    {value:8,label:'假冒品牌'},
                    {value:9,label:'收到商品少件或破损'},
                    {value:10,label:'其他'},
                ],
                refundTypes:[
                    {value:1,label:'退款'},
                    {value:2,label:'退货退款'}
                ],
                refundTypeDisabled:false,
                productNumDisabled:false,
                refundCashAmountApplyDisabled:false,
                refundBalanceAmountApplyDisabled:false,
                applyRefundDisabled:false,
                isRefundCouponDisabled:false,
                delProductDisabled:false,
                dataPlatform:''
            }
        },
        components:{
            defaultSec,tablePagination
        },
        mounted(){
            this.initList(this.refundNo,this.orderNo,this.pageType)
        },
        methods: {
            initList(refundNo,orderNo,pageType) {
                if(refundNo == "undefined"){
                    refundNo = "";
                }
                if(pageType == 2 || pageType == 3){
                    // 如果是天猫售后单可以改：删除
                    this.delProductDisabled=true;
                }
                this.$apiGet('lechun-csms/refundApi/queryRefundList',{refundNo,orderNo,pageType}).then(res => {
                    this.detailInfo = res.refunds.length>0?res.refunds[0]:{}
                    this.detailInfo.refundProductResVoList = res.refunds[0].refundProductResVoList;
                    console.log(this.detailInfo.afterSaleType);
                    if(this.detailInfo.afterSaleType == 1){ //售中退款
                        this.detailInfo.refundType = this.refundTypes[0].value;
                        this.refundTypeDisabled=true;
                        // 如果是天猫售后单可以改：数量
                        this.productNumDisabled=true;
                        // 如果是天猫售后单可以改：金额
                        this.refundCashAmountApplyDisabled=true;
                        this.refundBalanceAmountApplyDisabled=true;
                    }
                    if(this.detailInfo.refundNumAmountMax <= 0){
                        this.applyRefundDisabled=true;
                    }
                    if(this.detailInfo.couponPay == false){
                        this.isRefundCouponDisabled = true;
                        this.detailInfo.isRefundCoupon = this.arr[0].value;
                    }
                    this.dataPlatform = res.refunds[0].dataPlatform;
                })
            },

            handleChangeProductNum(row){
                row.refundCashAmountApply=row.productNum*row.productPrice;
            },

            toApplyRefund(){
                if(this.detailInfo.refundType == '' || this.detailInfo.refundType == null){
                    alert("请选择退款类型");
                    return;
                }
                if(this.detailInfo.refundReasonCode == '' || this.detailInfo.refundReasonCode == null){
                    alert("请选择退款原因");
                    return;
                }
                if(this.detailInfo.isRefundCoupon == '' || this.detailInfo.isRefundCoupon == null){
                    alert("请选择是否退优惠券");
                    return;
                }
                var formJson = {
                    refundNo:this.detailInfo.refundNo,
                    isRefundCoupon:this.detailInfo.isRefundCoupon,
                    refundReasonCode: this.detailInfo.refundReasonCode,
                    orderNo: this.detailInfo.orderNo,
                    orderMainNo: this.detailInfo.orderMainNo,
                    orderSource: this.orderSource,
                    remark: this.detailInfo.remark,
                    refundType: this.detailInfo.refundType,
                    refundProductList:this.detailInfo.refundProductResVoList
                };
                console.log(JSON.stringify(formJson));
                this.$apiPost('lechun-csms/refundApi/refundApply',formJson).then(res=>{
                    this.$message({
                        message: '保存成功',
                        type: 'success'
                    });
                    //跳转到退款列表页
                    this.$router.push({path:'/refundsList',name:'refundsList',params:{}})
                })
            },
            toCheckRefund(agreeStatus){
                if(this.detailInfo.refundType == '' || this.detailInfo.refundType == null){
                    alert("请选择退款类型");
                    return;
                }
                if(this.detailInfo.refundReasonCode == '' || this.detailInfo.refundReasonCode == null){
                    alert("请选择退款原因");
                    return;
                }
                if(agreeStatus == 'agree' && this.detailInfo.dataPlatform == 'taobao_mall'){
                  this.$prompt('第二次点击请输入验证码，第一次不用', '二次验证码', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    inputType: 'text'
                  }).then(({ value }) => {
                      this.msgCode = value;
                      this.checkRefund(agreeStatus);
                  }).catch(() => {
                    // this.$message({
                    //     type: 'info',
                    //     message: '取消输入'
                    // });
                  });
                } else {
                    this.checkRefund(agreeStatus);
                }

            },
            checkRefund(agreeStatus){
                var formJson = {
                    refundNo:this.detailInfo.refundNo,
                    isRefundCoupon:this.detailInfo.isRefundCoupon,
                    refundReasonCode: this.detailInfo.refundReasonCode,
                    orderNo: this.detailInfo.orderNo,
                    orderSource: this.orderSource,
                    orderMainNo: this.detailInfo.orderMainNo,
                    remark: this.detailInfo.remark,
                    refundType: this.detailInfo.refundType,
                    refundProductList:this.detailInfo.refundProductResVoList
                };
                console.log(JSON.stringify(formJson));
                this.$apiPost('lechun-csms/refundApi/refundCheck?agreeStatus=' + agreeStatus + '&msgCode=' + this.msgCode,formJson).then(res=>{
                    this.$message({
                        message: '保存成功',
                        type: 'success'
                    });
                    //跳转到退款列表页
                    this.$router.push({path:'/refundsList',name:'refundsList',params:{}})
                })
            },
            addProduct(){
                this.form.refundProductResVoList.push({
                    refundNo:'',
                    productId:'',
                    productNum:1,
                    productRefundAmount:0,
                })
            },
            delProduct(index){
                console.log(index)
                this.detailInfo.refundProductResVoList.splice(index,1)
            },
            updateReason(){

            }
        }
    }
</script>


<style lang="scss" rel="stylesheet/scss">

    .refund-detail{
        .red .el-form-item__label{
            color: red;
        }
        .el-form-item__label{
            font-size: 12px!important;
        }
        .el-input{
            width: 100%;
            height: 32px;
        }
        .el-tag{
            width: 100%;
            height: 32px;
        }
        .el-avatar{
            display: block;
            margin: 0 auto;
        }
    }
</style>